.wheelContainer {
  width: 280px;
  height: 280px;
}

.wheelContainer > div {
  height: 100%;
  width: 100%;
  max-height: unset;
  max-width: unset;
}
