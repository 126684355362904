#inputID::placeholder {
  color: #343538;
  opacity: 1;
}
/* .click {
    display: flex;
    top: 10;
    position: relative;
    background-color: green;
    justify-content: center;
    width: 60px;
    left: 120;
    border-radius: 15;
    cursor: pointer;
  } */
.click:hover {
  width: 120px;
  background-color: black;
}
.outroClick {
  width: 50px;
  height: 50px;
  margin: auto;
}
.click {
  height: 50px;
  background-color: red;
  transition: all 0.5s;
}

.click:hover {
  margin-left: -100px;
}
/* main css for add button  */
#outer {
  width: 80px;
  height: 20px;
  margin: auto;
  position: relative;
  left: 25px;
  top: 10px;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: #2b5a96;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 60%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2b5a96 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
#inner {
  height: 35px;
  background-color: rgb(69, 206, 69);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

#inner:hover {
  margin-left: -50px;
}
.pagenum {
  display: none;
}
.pagination {
  display: flex;
}
.prepage {
  margin-right: 10px;
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.nextpage {
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.nextpage:hover {
  background-color: #499b0f;
}
.prepage:hover {
  background-color: #499b0f;
}
